

























































































































































































































































































































































































































































































































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';

import { ObjectId } from 'bson';
import axios from 'axios';
import { loading } from 'pcv4lib/src';
import { useUserGetters, useDbGetters } from '@/store';
import Loading from '@/components/Loading.vue';
import Profile from '@/components/Profile.vue';
import { IProgramDoc, defaultAdkState } from './MobileEmployer/types/program';
import { AdkName, IProgramAdk } from './MobileEmployer/types/adk';
import { MeetingDay, MeetingTime, MeetingCadence } from './MobileEmployer/types/mobile';
import {
  ContactMethod,
  ContactPreference,
  DirectorySettings
} from './MobileEmployer/types/directory';

export default defineComponent({
  name: 'MobileEmployer',

  components: {
    Loading,
    Profile
  },

  setup(props, ctx) {
    const { collection } = useDbGetters(['collection']);
    const { getObjectId } = useUserGetters(['getUser', 'getObjectId']);
    const programDocs = ref<Array<IProgramDoc>>([]);
    const messageContent = ref('');
    const user = ref<
      | {
          _id: ObjectId;
          firstName: string;
          lastName: string;
          profile: {
            medium: string;
          };
        }
      | undefined
    >();
    const adkLocalState = ref<typeof defaultAdkState>({ ...defaultAdkState });
    const participants = ref([]);
    const internshipStatusDialog = ref(false);
    const disabledStudents = ref([]);
    const selectedStudent = ref({});

    const myDirectoryPreferences = computed<DirectorySettings | undefined>({
      get: () => {
        if (!user.value) return undefined;
        return adkLocalState.value.directory.directoryPreferences.find(preferences =>
          preferences.userId.equals(user.value!._id)
        );
      },
      set: newVal => {
        if (!user.value || !newVal) return;
        const myDirectoryIndex = adkLocalState.value.directory.directoryPreferences.findIndex(
          preferences => preferences.userId.equals(user.value!._id)
        );
        if (myDirectoryIndex === -1)
          adkLocalState.value.directory.directoryPreferences.push(newVal);
        else adkLocalState.value.directory.directoryPreferences[myDirectoryIndex] = newVal;
      }
    });

    const _selectedDoc = ref<IProgramDoc>();
    /**
     * Computed, current `studentDoc`.
     * The setter inserts default values into the local state
     * and updates `adkLocalState` to match the specific doc.
     */
    const selectedDoc = computed({
      get: () => _selectedDoc.value,
      set: newVal => {
        _selectedDoc.value = newVal;
        if (newVal) {
          // loop through the different adk objects (keys of AdkState) and set default values if needed.
          Object.keys(adkLocalState.value).forEach(adk => {
            adkLocalState.value[adk] = newVal.adks[adk]
              ? { ...newVal.adks[adk] }
              : { ...defaultAdkState[adk] };
          });
          // set default value for myDirectoryPreferences. kinda jank but fine for now.
          if (!myDirectoryPreferences.value)
            myDirectoryPreferences.value = {
              userId: user.value!._id,
              contactMethods: [],
              contactPreferences: [],
              hideEmail: false,
              hidePhone: false
            };
        }
      }
    });

    const programDialog = ref(false);

    const checkDisabledStatus = adksArray => {
      const adkMobile = adksArray.filter(o => o.name === 'mobile');
      return adkMobile[0] !== undefined && adkMobile[0].internshipCompletion !== undefined
        ? adkMobile[0].internshipCompletion
        : false;
    };

    const fetchParticipantData = async () => {
      const query = [
        {
          $match: {
            participant_id: { $in: selectedDoc?.value?.participants },
            program_id: selectedDoc?.value?._id,
            $and: [{ 'adks.name': 'offer' }, { 'adks.offerStatus': true }]
          }
        },
        {
          $lookup: {
            from: 'User',
            foreignField: '_id',
            localField: 'participant_id',
            as: 'userDocs'
          }
        },
        {
          $unwind: {
            path: '$userDocs',
            includeArrayIndex: 'index'
          }
        }
      ];

      participants.value = await collection.value!('Student').aggregate(query);
      participants.value.forEach((participantsdata, index) => {
        const mobileAdk = participantsdata.adks.filter(o => o.name === 'mobile');
        participants.value[index].clockAdjust =
          mobileAdk[0]?.clockAdjustedHours === undefined ? '' : mobileAdk[0]?.clockAdjustedHours;
        if (
          mobileAdk[0] !== undefined &&
          mobileAdk[0].internshipCompletion !== undefined &&
          mobileAdk[0].internshipCompletion
        ) {
          disabledStudents.value.push(participantsdata.participant_id.toHexString());
        }
      });
      participants.value.sort((a, b) => {
        if (a.clockAdjust > b.clockAdjust) return -1;
        return a.clockAdjust < b.clockAdjust ? 1 : 0;
      });
    };

    const loadData = async () => {
      user.value = await collection.value!('User').findOne(
        {
          _id: getObjectId.value
        },
        {
          projection: {
            _id: 1,
            firstName: 1,
            lastName: 1,
            profile: {
              medium: 1
            }
          }
        }
      );

      programDocs.value = (
        await collection.value!('Program').find(
          {
            organizers: getObjectId.value,
            adks: {
              $exists: true
            }
          },
          {
            projection: {
              programName: 1,
              organizers: 1,
              adks: 1,
              participants: 1
            }
          }
        )
      ).map((program): IProgramDoc => {
        return {
          ...program,
          adks: {
            participants: program.participants,
            offer: program.adks.find(
              (adk: { [x: string]: string; name: AdkName }) =>
                adk.name === 'offer' || adk?.adk === 'offer'
            ),
            mobile: program.adks.find((adk: { name: AdkName }) => adk.name === 'mobile'),
            directory: program.adks.find((adk: { name: AdkName }) => adk.name === 'directory'),
            objective: program.adks.find((adk: { name: AdkName }) => adk.name === 'objective')
          }
        };
      });

      [selectedDoc.value] = programDocs.value;

      await fetchParticipantData();

      if (!selectedDoc.value) ctx.root.$router.push({ name: 'landing' });
    };

    /**
     * Get a specific adk object stored in the db.
     */
    function getAdk<Adk extends IProgramAdk>(adk: Adk['name']) {
      return selectedDoc.value?.adks[adk];
    }

    /**
     * Save specific adk object to the db.
     */
    async function saveAdk<Adk extends IProgramAdk>(adk: Adk['name']) {
      const adkObj = adkLocalState.value[adk];
      if (!adkObj) throw new Error(`cannot save ${adk}, the value is ${adkObj}`);
      if (!selectedDoc.value?.adks[adk]) {
        try {
          await collection.value!('Program').updateOne(
            {
              _id: selectedDoc.value?._id
            },
            {
              $push: {
                adks: adkObj
              }
            }
          );
          selectedDoc.value!.adks[adk] = adkObj;
        } catch (e) {
          console.log(`Error while pushing new ${adk} adk with value ${adkObj} to programDoc`);
          throw e;
        }
      } else {
        try {
          await collection.value!('Program').updateOne(
            {
              _id: selectedDoc.value?._id,
              adks: { $elemMatch: { name: adk } }
            },
            {
              $set: {
                'adks.$': adkObj
              }
            }
          );
          selectedDoc.value.adks[adk] = adkObj;
        } catch (e) {
          console.log(`Error while setting ${adk} adk to ${adkObj}`);
          throw e;
        }
      }
    }

    const saveAllLoading = ref(false);
    async function saveAllAdks() {
      saveAllLoading.value = true;
      // have to use any here, typescript complains that string doesn't cast to AdkName
      const requests = Object.keys(adkLocalState.value).map((adk: any) => saveAdk(adk));
      await Promise.all(requests);
      saveAllLoading.value = false;
    }

    async function postMessageOnDiscord() {
      let result;
      const content = `${messageContent.value}\n\n - ${user.value.firstName} ${user.value.lastName}`;
      await axios
        .post(`${process.env.VUE_APP_API_ENDPOINT}newdiscord/sendmessage`, {
          programName: selectedDoc.value?.programName,
          message: content
        })
        .then(res => {
          result = res.data;
        })
        .catch(err => {
          console.log('post message error: ', err);
          result = err;
        });
      return result;
    }

    // for the links
    const test1 = ref('');
    const test2 = ref('');
    const test3 = ref('');

    function onChange(participantData) {
      selectedStudent.value = participantData;
      internshipStatusDialog.value = true;
    }

    const finishInternship = async () => {
      await collection.value!('Student').updateOne(
        {
          participant_id: selectedStudent.value?.participant_id,
          program_id: selectedStudent.value?.program_id,
          adks: { $elemMatch: { name: 'mobile' } }
        },
        {
          $set: {
            'adks.$.internshipCompletion': true
          }
        }
      );
      await fetchParticipantData();
      selectedStudent.value = {};
      internshipStatusDialog.value = false;
    };

    const closeInternshipStatusDialog = () => {
      disabledStudents.value.splice(
        disabledStudents.value.indexOf(selectedStudent.value._id.toHexString()),
        1
      );
      selectedStudent.value = {};
      internshipStatusDialog.value = false;
    };

    return {
      loadData,
      user,
      programDialog,
      participants,
      programDocs,
      selectedDoc,
      adkLocalState,
      MeetingDay,
      MeetingTime,
      MeetingCadence,
      myDirectoryPreferences,
      ContactMethod,
      ContactPreference,
      internshipStatusDialog,
      disabledStudents,
      selectedStudent,
      fetchParticipantData,
      finishInternship,
      closeInternshipStatusDialog,
      onChange,
      getAdk,
      saveAllAdks,
      checkDisabledStatus,
      saveAllLoading,
      test1,
      test2,
      test3,
      getHelpNow: false,
      messageContent,
      postMessage: loading(postMessageOnDiscord, null, null)
    };
  }
});
